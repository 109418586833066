import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

const RetailDetails = () => {
  const [productId, setProductId] = useState('D5000000040CPIBFDA');
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const handleInputChange = (event) => {
    setProductId(event.target.value);
  }

  const handleButtonClick = () => {
    setIsButtonClicked(true);
  }

  if (isButtonClicked) {
    return (
      <Redirect
        to={{
          pathname: "/retail-product",
          state: { productId }
        }}
      />
    );
  }

  return (
    <div className='container'>
      <div className='product-input-block'>
        <input placeholder='Product ID' value={productId} onChange={handleInputChange} />
        <button disabled={!productId} onClick={handleButtonClick}>Render webshop</button>
      </div>
    </div>
  )
}

export default RetailDetails;