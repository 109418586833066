import { Link } from 'react-router-dom';

const Footer = () => {
  const handleOnClick = () => window.scrollTo(0, 0);
  const withDialog = location.pathname.includes('withdialog');

  return (
    <footer>
      <Link to={`/${withDialog ? 'withdialog/' : ''}privacy`} className='item' onClick={handleOnClick}>
        Privacy
      </Link>
      <Link to={`/${withDialog ? 'withdialog/' : ''}terms`} className='item' onClick={handleOnClick}>
        Terms
      </Link>
      <Link to={`/${withDialog ? 'withdialog/' : ''}imprint`} className='item' onClick={handleOnClick}>
        Imprint
      </Link>
      <Link to={`/${withDialog ? 'withdialog/' : ''}contact`} className='item' onClick={handleOnClick}>
        Contact
      </Link>
      <a href={withDialog ? '/' : '/withdialog/film-list'}>{withDialog ? 'Compeso Mock' : 'Dialog Option Mock'}</a>
    </footer>
  );
};

export default Footer;
