import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { FiLogIn, FiLogOut, FiShoppingCart } from 'react-icons/fi';
import { IFRAME_LANGUAGES } from './App';
import { ICartState, ICenter, ILoginState } from '../interfaces';
import NavigationLinks from './navigation-links';
import classNames from 'classnames';
import '../styles/top-nav.css';

declare var config: any;

export interface NavigationProps {
  onCenterSelection(center: any): any;
  onLanguageSelection(language: any): any;
  updateTimestamp(): any;
  selectedCenter: ICenter | undefined;
  selectedLanguage: string;
  centers: ICenter[] | undefined;
  loginState?: ILoginState;
  cartState?: ICartState;
}

const Navigation = ({
  selectedCenter,
  centers,
  onCenterSelection,
  updateTimestamp,
  onLanguageSelection,
  selectedLanguage,
  loginState,
  cartState,
}: NavigationProps) => {
  const [cartItemsAmount, setCartItemsAmount] = useState(0);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isCenterListOpen, setIsCenterListOpen] = useState(false);
  const withDialog = location.pathname.includes('withdialog');

  useEffect(() => {
    setCartItemsAmount(cartState ? cartState.items.reduce((sum, { amount }) => sum + amount, 0) : 0);
  }, [cartState]);

  const hideMenu = () => {
    setMobileMenuOpen(false);
  };

  const toggleMenu = () => {
    setMobileMenuOpen((p) => !p);
  };

  const toggleCenterSelectionList = () => {
    setIsCenterListOpen((p) => !p);
  };

  const handleCenterSelect = (winticketId: string) => {
    onCenterSelection(winticketId);
    setIsCenterListOpen(false);
  };

  const hideMenuWithTimestampUpdate = () => {
    hideMenu();
    updateTimestamp();
  };

  return (
    <>
      <div className={classNames('top-bar', { 'with-dialog': withDialog })}>
        <Link to={withDialog ? '/withdialog' : '/'} className='item' onClick={hideMenuWithTimestampUpdate}>
          {withDialog ? `Compeso Dialog Mock(${selectedCenter?.name})` : `Compeso mock (${selectedCenter?.name})`}
        </Link>
        <div className='top-bar-selectors'>
          <Link to={`/${withDialog ? 'withdialog/' : ''}checkout`} className='cart-link'>
            Cart <FiShoppingCart />
            {cartItemsAmount > 0 && <span className='cart-badge'>{cartItemsAmount}</span>}
          </Link>
          {loginState ? (
            <Link to={`/${withDialog ? 'withdialog/' : ''}logout`}>
              Logout <FiLogOut />
            </Link>
          ) : (
            <Link to={`/${withDialog ? 'withdialog/' : ''}login`}>
              Login <FiLogIn />
            </Link>
          )}
          <Tooltip float anchorSelect='.cart-link'>
            {cartItemsAmount === 0 || !cartState ? (
              <p>No items added to cart</p>
            ) : (
              cartState.items.map((item, key) => (
                <p key={`${item.amount}${item.name}${item.price}-${key}`}>
                  {item.amount}x {item.name} - {item.price}
                </p>
              ))
            )}
          </Tooltip>
          <select value={selectedLanguage} onChange={onLanguageSelection}>
            {IFRAME_LANGUAGES.map((lang) => (
              <option key={lang} value={lang}>
                {lang}
              </option>
            ))}
          </select>
          <div className='center-selection'>
            <i className='fa fa-map-marker' aria-hidden='true' onClick={toggleCenterSelectionList}></i>
            <ul className={classNames('center-list', { opened: isCenterListOpen })}>
              {centers?.map((center: ICenter) => (
                <li
                  key={center.winticketId}
                  className='center-option'
                  onClick={() => handleCenterSelect(center.winticketId)}
                >
                  {center.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className={classNames('menu', { 'is-open': isMobileMenuOpen, 'with-dialog': withDialog })}>
        <div className='hamburger-icon' onClick={toggleMenu}>
          <i className='fa fa-bars' />
        </div>
        <NavigationLinks
          config={config}
          selectedLanguage={selectedLanguage}
          selectedCenter={selectedCenter}
          withDialog={withDialog}
          hideMenuWithTimestampUpdate={hideMenuWithTimestampUpdate}
        />
      </div>
    </>
  );
};

export default Navigation;
