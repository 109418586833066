import { useState, useEffect } from 'react';
import Navigation from './navigation';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Api from '../api/api';
import Webshop from './webshop';
import useScript from '../hooks/useScript';
import FilmList from './film-list';
import VoucherList from './voucher-list';
import RetailDetails from './retail-details';
import { ICartState, ICenter, ILoginState, IOnLoginPayload, IOnLoginViaWebViewAuthPayload } from '../interfaces';
import queryString from 'query-string';
import Footer from './footer';
import { decryptRefreshTokenData } from 'src/utils';

// import runtimeconfig.js runtime
declare var config: any;
export const IFRAME_LANGUAGES = ['DE', 'EN'];
const COMPESO_MOCK_SELECTED_CENTER = 'compesoMockSelectedCenter';

const App = () => {
  const [counter, setCounter] = useState(1);
  const [selectedCenter, setSelectedCenter] = useState<ICenter>();
  const [selectedLanguage, setSelectedLanguage] = useState<string>('DE');
  const [centers, setCenters] = useState<ICenter[]>();
  const [timestamp, setTimestamp] = useState<number>(Date.now());
  const status = useScript(config.apiService.webshop.endpoint + '/assets/js/webshop-scripts.js');
  const query = queryString.parse(location.search.replace('?', ''));
  const [loginState, setLoginState] = useState<ILoginState>();
  const [cartState, setCartState] = useState<ICartState>();
  const withDialog = location.pathname.includes('withdialog');

  const handleCenterSelection = (selectedCenterWintickedId: string) => {
    const selectedCenter = centers?.find((center) => center.winticketId === selectedCenterWintickedId);

    if (selectedCenter) {
      setSelectedCenter(selectedCenter);
      localStorage.setItem(COMPESO_MOCK_SELECTED_CENTER, JSON.stringify(selectedCenter));

      if (location.pathname.includes('seatingplan') && !withDialog) {
        window.location.href='/';
      }
    }
  };

  const handleLanguageSelection = (e) => {
    setSelectedLanguage(e.target.value);
  };

  const getCenters = async (centerid?: string) => {
    const centers = await Api.retrieveCenters();
    const selectedCenterObj = localStorage.getItem(COMPESO_MOCK_SELECTED_CENTER);

    let selectedCenterFromLocalStorage: ICenter | undefined;
    let selectedCenter: ICenter | undefined;

    if (centers) {
      setCenters(centers);
      selectedCenter = centers.find((center) => center.winticketId === centerid);
    }

    if (selectedCenterObj) {
      selectedCenterFromLocalStorage = JSON.parse(selectedCenterObj) as ICenter;
    }

    setSelectedCenter(selectedCenterFromLocalStorage ?? selectedCenter ?? (centers?.length ? centers[0] : undefined));
  };

  const updateTimestamp = () => {
    setTimestamp(Date.now());
  };

  const processIframeScrollPositionAdvice = (positionInfo) => {
    const elements = document.getElementsByClassName('iframe-webshop') as HTMLCollectionOf<HTMLElement> | null;

    if (elements && elements.length > 0) {
      const iframe = elements[0];
      const positionAdvisedToBeVisible = positionInfo.advisedVisiblePosition;
      const desiredVisiblePosition = iframe.offsetTop + Number(positionAdvisedToBeVisible);

      window.focus();
      window.scrollTo(0, desiredVisiblePosition);
    }
  };

  useEffect(() => {
    const cprws = query.cprws?.toString();
    if (cprws) {
      const { parameters, centerid } = JSON.parse(cprws);

      getCenters(centerid);

      if (parameters.language && IFRAME_LANGUAGES.includes(parameters.language.toUpperCase())) {
        setSelectedLanguage(parameters.language.toUpperCase());
      }
    } else {
      getCenters(undefined);
    }
  }, []);

  useEffect(() => {
    counter > 0 &&
      setTimeout(() => {
        if (typeof window.iFrameResize !== 'function') {
          if (typeof window?.Compeso?.loadIFrameResizer === 'function') {
            window.Compeso.loadIFrameResizer();
          }
          setCounter(counter + 1);
        }
      }, 1000);
  }, [counter]);

  useEffect(() => {
    if (
      status === 'ready' &&
      typeof window?.Compeso?.setLoginChangedCallback === 'function' &&
      typeof window.Compeso.getLogin === 'function'
    ) {
      window.Compeso.setLoginChangedCallback((info) => {
        console.log('Login changed in iframe:', info);
        setLoginState(info);
      });
      window.Compeso.getLogin();
    }

    if (
      status === 'ready' &&
      typeof window?.Compeso?.setCartChangedCallback === 'function' &&
      typeof window.Compeso.getCart === 'function'
    ) {
      window.Compeso.setCartChangedCallback((info) => setCartState(info));
      window.Compeso.getCart();
    }

    if (status === 'ready' && typeof window?.Compeso?.setScrollPositionAdviceCallback === 'function') {
      window.Compeso.setScrollPositionAdviceCallback((info) => processIframeScrollPositionAdvice(info));
    }

    if (status === 'ready' && typeof window?.Compeso?.setLoginFieldsDisplayedCallback === 'function') {
      window.Compeso.setLoginFieldsDisplayedCallback(() => {
        console.log('Webshop in iframe is displaying login fields.');
      });
    }

    if (status === 'ready' && typeof window?.Compeso?.setOnLoginCallback === 'function') {
      window.Compeso.setOnLoginCallback((data) => {
        console.log('User logged in inside iframe.');

        // if (data) {
        //   console.log('Posted data:', data);
        //   decryptRefreshTokenData(data);
        // }
      });
    }

    if (status === 'ready' && typeof window?.Compeso?.setOnLogoutCallback === 'function') {
      window.Compeso.setOnLogoutCallback(() => console.log('User logged out inside iframe.'));
    }

    if (status === 'ready' && typeof window?.Compeso?.setOnLoginViaWebViewAuthCallback === 'function') {
      window.Compeso.setOnLoginViaWebViewAuthCallback((data) => {
        console.log(`User was ${!data?.isLoggedIn ? 'not ' : ''}successfully logged in.`);
      });
    }
  }, [status]);

  useEffect(() => {
    if (withDialog) {
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('with-dialog');
    }
  }, []);

  if (!selectedCenter || status !== 'ready') {
    return null;
  }

  return (
    <>
      <BrowserRouter>
        <Navigation
          onCenterSelection={handleCenterSelection}
          onLanguageSelection={handleLanguageSelection}
          selectedCenter={selectedCenter}
          selectedLanguage={selectedLanguage}
          centers={centers}
          updateTimestamp={updateTimestamp}
          loginState={loginState}
          cartState={cartState}
        />
        <Switch>
          <Route path='/voucher-list' render={() => <VoucherList selectedCenter={selectedCenter} />} />
          <Route path='/retail-details' render={() => <RetailDetails />} />
          <Route
            path='/withdialog/film-list'
            render={() => <FilmList selectedCenter={selectedCenter} selectedLanguage={selectedLanguage} withDialog />}
          />
          <Route
            path={['/', '/withdialog', '/film-list']}
            exact
            render={() => <FilmList selectedCenter={selectedCenter} selectedLanguage={selectedLanguage} />}
          />
          <Route
            path='*'
            render={() => (
              <Webshop key={timestamp} selectedCenter={selectedCenter} selectedLanguage={selectedLanguage} />
            )}
          />
        </Switch>
        <Footer />
      </BrowserRouter>
    </>
  );
};

export default App;

declare global {
  interface Window {
    Compeso:
      | undefined
      | {
          loadIFrameResizer(): void;
          updateOrInitializeIFrameResizer(): void;
          loadContent(shopbaseurl: string): void;
          getParentIFrame(): unknown;
          getLogin(): any;
          setLoginChangedCallback(callback: (info: ILoginState | undefined) => void): boolean;
          getCart(): any;
          setCartChangedCallback(callback: (info: ICartState | undefined) => void): boolean;
          setLoginFieldsDisplayedCallback(callback: () => void): boolean;
          setOnLoginCallback(callback: (data: IOnLoginPayload | undefined) => void): boolean;
          setOnLogoutCallback(callback: () => void): boolean;
          setOnLoginViaWebViewAuthCallback(
            callback: (data: IOnLoginViaWebViewAuthPayload | undefined) => void,
          ): boolean;
          setScrollPositionAdviceCallback(callback: any): boolean;
        };
    iFrameResize: any;
  }
}
