import { Link } from 'react-router-dom';
import moment from 'moment';
import { ICenter, IMovie } from '../interfaces';

declare var config: any;

interface IFilmProps {
  film: IMovie;
  selectedCenter: ICenter | undefined;
  selectedLanguage: string;
  withDialog?: boolean;
}

const Film = ({ film, selectedCenter, selectedLanguage, withDialog }: IFilmProps) => {
  const getOptionalParams = () => {
    const displayOptionParam = 'displayoption';
    const closeUrlParam = 'closeurl';

    const params = new URLSearchParams();

    if (withDialog) {
      params.set(displayOptionParam, 'dialog');
      params.set(closeUrlParam, '/withdialog/film-list');
    }

    if (!params.has(displayOptionParam) && !params.has(closeUrlParam)) {
      return '';
    }

    return `?${params.toString()}`;
  };

  return (
    <div className='film'>
      <h2 className='film-title'>{film.name}</h2>
      <div className='film-info-wrapper'>
        <img src={`${film.imageUrl}&size=100`} alt={`${film.name} - Poster`} />
        <div className='film-performances-list'>
          {film.performances &&
            film.performances.map((performance) => (
              <div className='film-performance' key={performance.id}>
                <Link
                  to={{
                    pathname: withDialog
                      ? `${config.apiService.webshop.endpoint}/landingpage?center=${selectedCenter?.winticketId}&language=${selectedLanguage}&page=seatingplan&performance=${performance.id}&displayoption=dialog&closeurl=${window.location.origin}/withdialog/film-list`
                      : `/seatingplan${getOptionalParams()}`,
                    state: { performance },
                  }}
                  target={withDialog ? '_top' : undefined}
                >
                  <p>{moment(performance.performanceDateTime).parseZone().format('DD.MM.YYYY')}</p>
                  <p>{moment(performance.performanceDateTime).parseZone().format('HH:mm')}</p>
                  <p>{performance.auditoriumName}</p>
                </Link>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Film;
