import apisauce from 'apisauce';
import { IApiError, ICenter, isApiError } from 'src/interfaces';

// import runtimeconfig.js runtime
declare var config: any;

class Api {
  apiBackend = apisauce.create({
    baseURL: config.apiService.webshop.api_endpoint + '/api',
    headers: {
      'Content-Type': 'application/json',
      'CENTER-ID': config.center,
    },
    timeout: 60000,
  });

  retrieveCenters = async () => {
    const result = await this.apiBackend.get<ICenter[] | IApiError>(`/centers`);

    if (!result?.ok || !result.data || isApiError(result.data)) {
      console.log('mock error retrieveCenters Error result:', result);
      return;
    }

    return result.data;
  };

  retrieveFilms = async (
    cinemaDateFrom: string,
    cinemaDateTo: string,
    performanceDateFrom: string,
    performanceDateTo: string,
    includeFilmCustomFields: boolean,
    includeFilmRelease: boolean,
    includeFilmReleaseCustomFields: boolean,
    center: string,
    language: string,
  ): Promise<any> => {
    const response = await this.apiBackend.get(
      '/films',
      {
        'cinemadate.from': cinemaDateFrom,
        'cinemadate.to': cinemaDateTo,
        'performancedate.from': performanceDateFrom,
        'performancedate.to': performanceDateTo,
        'include.film.customfields': includeFilmCustomFields,
        'include.filmrelease': includeFilmRelease,
        'include.filmrelease.customfields': includeFilmReleaseCustomFields,
        locale: language.toLowerCase(),
      },
      {
        headers: { 'center-oid': center },
      },
    );
    if (response.status === 200) {
      return response;
    } else {
      console.log(`mock error retrieveFilms Error response: ${response.status}.`);
      return undefined;
    }
  };

  retrieveVouchers = async (center: string): Promise<any> => {
    const response = await this.apiBackend.get(
      `/configurations/product.voucherlist`,
      {},
      { headers: { 'center-oid': center } },
    );
    if (response.status === 200) {
      return response;
    } else {
      console.log(`mock error retrieveVouchers Error response: ${response.status}.`);
      return undefined;
    }
  };

  retrieveConfiguration = async <T = unknown>(configurationKey: string) => {
    const response = await this.apiBackend.get<T | IApiError>(`/configurations/${configurationKey}`);

    if (!response?.ok || !response.data || isApiError(response.data)) {
      console.log('mock error retrieveConfiguration Error result:', response);
      return undefined;
    }

    return response.data;
  };
}

export default new Api();
