import { useState, useEffect } from 'react';
import moment from 'moment';
import Film from './film';
import { ICenter, IMovie } from '../interfaces';
import api from '../api/api';
import PlaceholderView from './placeholder-view';
import '../styles/film-list.css';

interface IFilmListProps {
  selectedCenter: ICenter | undefined;
  selectedLanguage: string;
  withDialog?: boolean;
}

const FilmList = ({ selectedCenter, withDialog, selectedLanguage }: IFilmListProps) => {
  const [films, setFilms] = useState<IMovie[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchFilms = async () => {
      setIsLoading(true);
      const from = moment().add(0, 'days').format('YYYY-MM-DD');
      const to = moment().add(7, 'days').format('YYYY-MM-DD');

      const result = await api.retrieveFilms(
        from,
        to,
        from,
        to,
        true,
        true,
        true,
        selectedCenter?.winticketId ?? '',
        selectedLanguage,
      );

      if (result?.ok) {
        setFilms(result.data);
      }

      setIsLoading(false);
    };

    fetchFilms();
  }, [selectedCenter]);

  if (isLoading) {
    return <PlaceholderView>Loading...</PlaceholderView>;
  }

  if (!films.length) {
    return <PlaceholderView>No films</PlaceholderView>;
  }

  return (
    <div className='film-list'>
      {films.map((film) => (
        <Film
          key={film.name}
          film={film}
          selectedCenter={selectedCenter}
          selectedLanguage={selectedLanguage}
          withDialog={withDialog}
        />
      ))}
    </div>
  );
};

export default FilmList;
