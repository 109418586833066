export const isApiError = <T>(obj: IApiError | T): obj is IApiError => {
  return (obj as IApiError).errorCode !== undefined;
};

export interface IApiError {
  errorCode: string;
  errorMessageTechnical: string;
}

export interface ILoginState {
  loginExpires: undefined | string;
}

export interface ICartItem {
  amount: number;
  name: string;
  price: number;
}

export interface ICartState {
  expires: string;
  items: ICartItem[];
  price: number;
}

export interface IOnLoginPayload {
  refreshToken: string;
}

export interface IOnLoginViaWebViewAuthPayload {
  isLoggedIn: boolean;
}

export interface ICenter {
  winticketId: string;
  cityName?: string;
  latitude?: number;
  longitude?: number;
  name?: string;
  timezone?: string;
  shortName?: string;
}

export interface IMovie {
  id: string;
  performances: IPerformance[];
  name: string;
  nationWideStart: Date;
  director: string;
  imageUrl: string;
  genres: string[];
  ageRating: string;
}

export interface IPerformance {
  id: string;
  name: string;
  performanceDateTime: string;
  cinemaDate: string;
  auditoriumName: string;
  releaseTypeName: string;
  filmReleaseId: string;
  weekfilmSortOrderPrio: number;
  imageUrl?: string;
}

export interface IVoucher {
  name: string;
  voucherId: string;
}
