import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../api/api';
import { ICenter, IVoucher } from '../interfaces';
import PlaceholderView from './placeholder-view';
import '../styles/voucher-list.css';

type VoucherListProps = {
  selectedCenter: ICenter;
};

const VoucherList: React.FC<VoucherListProps> = (props) => {
  const { selectedCenter } = props;
  const [vouchers, setVouchers] = useState<IVoucher[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchVouchers = async () => {
      setIsLoading(true);
      const result = await api.retrieveVouchers(selectedCenter?.winticketId);

      if (result) {
        setVouchers(result.data);
      }

      setIsLoading(false);
    };

    fetchVouchers();
  }, [selectedCenter]);

  if (isLoading) {
    return <PlaceholderView>Loading...</PlaceholderView>;
  }

  if (!vouchers.length) {
    return <PlaceholderView>There are no vouchers configured for the center</PlaceholderView>;
  }

  return (
    <div className='voucher-list'>
      {vouchers.map((voucher: IVoucher, i) => (
        <div className='voucher-item' key={`${voucher.voucherId}${i}`}>
          <div className='voucher'>
            <Link
              to={{
                pathname: '/purchase-voucher',
                state: { voucherId: voucher.voucherId },
              }}
            >
              {voucher.name}
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default VoucherList;
