import { Link } from 'react-router-dom';
import { ICenter } from '../interfaces';

interface Props {
  config: any;
  selectedCenter?: ICenter;
  selectedLanguage: string;
  withDialog?: boolean;
  hideMenuWithTimestampUpdate: VoidFunction;
}

const NavigationLinks = ({
  config,
  selectedCenter,
  selectedLanguage,
  withDialog,
  hideMenuWithTimestampUpdate,
}: Props) =>
  withDialog ? (
    <>
      <Link to='/withdialog/film-list' className='item' onClick={hideMenuWithTimestampUpdate}>
        Performance List Cinema
      </Link>
      <Link 
        to={{ pathname: `${config.apiService.webshop.endpoint}/landingpage?center=${selectedCenter?.winticketId}&language=${selectedLanguage}&page=productlist&displayoption=dialog&closeurl=${window.location.origin}/withdialog/film-list` }} 
        target='_top' 
        className='item' 
        onClick={hideMenuWithTimestampUpdate}
      >
        Product List
      </Link>
      <Link 
        to={{ pathname: `${config.apiService.webshop.endpoint}/landingpage?center=${selectedCenter?.winticketId}&language=${selectedLanguage}&page=retaillist&displayoption=dialog&closeurl=${window.location.origin}/withdialog/film-list` }} 
        target='_top' 
        className='item' 
        onClick={hideMenuWithTimestampUpdate}
      >
        Retail List
      </Link>
      <Link to='/withdialog/registration' className='item' onClick={hideMenuWithTimestampUpdate}>
        Registration
      </Link>
      <Link to='/withdialog/transaction-history' className='item' onClick={hideMenuWithTimestampUpdate}>
        Transaction history
      </Link>
      <Link to='/withdialog/my-account' className='item' onClick={hideMenuWithTimestampUpdate}>
        My account
      </Link>
    </>
  ) : (
    <>
      <Link
        to='/film-list?cs=[{"name":"marketing","consent":true},{"name":"preferences","consent":false},{"name":"localization","consent":false}]'
        className='item'
        onClick={hideMenuWithTimestampUpdate}
      >
        Performance List Cinema
      </Link>
      <a
        href={`${config.apiService.webshop.endpoint}/landingpage?center=${selectedCenter?.winticketId}&language=${selectedLanguage}&page=home&cs=[{"name":"marketing","consent":true},{"name":"preferences","consent":false}]`}
      >
        Performance List [with cs param]
      </a>
      <Link to='/performance-list' className='item' onClick={hideMenuWithTimestampUpdate}>
        Performance List
      </Link>
      <Link to='/product-list' className='item' onClick={hideMenuWithTimestampUpdate}>
        Product List
      </Link>
      <Link to='/retail-list' className='item' onClick={hideMenuWithTimestampUpdate}>
        Retail List
      </Link>
      <Link to='/retail-details' className='item' onClick={hideMenuWithTimestampUpdate}>
        Retail details
      </Link>
      <Link to='/registration' className='item' onClick={hideMenuWithTimestampUpdate}>
        Registration
      </Link>
      <Link to='/my-account' className='item' onClick={hideMenuWithTimestampUpdate}>
        My account
      </Link>
      <Link to='/transaction-history' className='item' onClick={hideMenuWithTimestampUpdate}>
        Transaction history
      </Link>
      <Link to='/voucher-list' className='item' onClick={hideMenuWithTimestampUpdate}>
        Voucher list
      </Link>
      <Link to='/loyalty' className='item' onClick={hideMenuWithTimestampUpdate}>
        Loyalty
      </Link>
      <Link to='/embeddedShop' className='item' onClick={hideMenuWithTimestampUpdate}>
        Embedded Shop
      </Link>
      <Link to='/voucher-verification' className='item' onClick={hideMenuWithTimestampUpdate}>
        Voucher verification
      </Link>
      <Link to='/consents' className='item' onClick={hideMenuWithTimestampUpdate}>
        My Consents
      </Link>
      <Link to='/newsletter' className='item' onClick={hideMenuWithTimestampUpdate}>
        Newsletter
      </Link>
    </>
  );

export default NavigationLinks;
