import React from 'react';
import '../styles/placeholder-view.css';

interface IPlaceholderView {
  children: React.ReactNode;
}

const PlaceholderView = ({ children }: IPlaceholderView) => {
  return <div className='placeholder-view'>{children}</div>;
};

export default PlaceholderView;
